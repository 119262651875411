import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import IVCard from '@invideoio/web-shared/components/Card';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import {
  AnalyticsProductType,
  ClickCtaType,
  ClickCtaUxSource,
} from 'src/types/analytics.types';
import { BrowserProps } from '../../types';

interface LargeCardsProps extends BrowserProps {
  title: string;
  content: string;
  ctaText: string;
  ctaAction: string | Function;
  mediaUrl?: string;
  tabletMedia?: string;
  isWebinar?: boolean;
}

const LargeCards = ({
  browser,
  title,
  ctaText,
  ctaAction,
  content,
  mediaUrl,
  tabletMedia = mediaUrl,
  isWebinar,
}: LargeCardsProps) => {
  const handleAction = () => {
    if (typeof ctaAction === 'function') {
      ctaAction();
    } else {
      setTimeout(() => {
        window.open(ctaAction, '_blank');
      }, 20);
    }
  };

  const onClick = () => {
    if (isWebinar) {
      sendAmplitudeAnalyticEvent(AmplitudeEvents.clickWebinar);
      sendRudderStackAnalyticEvent({
        event_name: 'click_cta',
        event_properties: {
          ux_source: ClickCtaUxSource.WebinarLargeCard,
          type: ClickCtaType.ClickWebinar,
          feature_utm: 'na',
          product: AnalyticsProductType.STUDIO,
        },
      });
    } else {
      sendAmplitudeAnalyticEvent(AmplitudeEvents.clickTutorial);
      sendRudderStackAnalyticEvent({
        event_name: 'click_cta',
        event_properties: {
          ux_source: ClickCtaUxSource.TutorialLargeCard,
          type: ClickCtaType.ClickTutorial,
          feature_utm: 'na',
          product: AnalyticsProductType.STUDIO,
        },
      });
    }

    handleAction();
  };

  return (
    <IVCard
      className={ivclass(
        'iv-flex iv-flex-col iv-justify-between md:iv-flex-row iv-items-center',
        'iv-bg-black-4',
        'iv-rounded-30',
        'iv-h-full',
        'xl:iv-min-h-[600px] lg:iv-min-h-[600px] md:iv-min-h-[350px]',
      )}
    >
      <div
        className={ivclass(
          'iv-flex iv-flex-col iv-justify-between',
          'md:iv-flex-1 iv-h-full iv-w-full',
        )}
      >
        <div
          className={ivclass(
            'iv-text-grey-85',
            'xl:iv-text-heading-s iv-text-heading-2xs sm:iv-text-heading-4xs',
            'xl:iv-m-heading-s iv-m-heading-2xs sm:iv-m-heading-4xs',
            'iv-font-extrabold',
            'xl:iv-px-40 iv-px-35 sm:iv-px-25 iv-pb-15',
            'xl:iv-pt-40 iv-pt-35 sm:iv-pt-25',
          )}
        >
          {title}
        </div>
        <div
          className={ivclass(
            'md:iv-grid md:iv-grid-cols-2',
            'iv-flex iv-flex-col iv-justify-between',
            'md:iv-mb-[18px]',
            'md:iv-hidden',
            'sm:iv-order-[-1]',
          )}
        >
          <div
            className={ivclass(
              'iv-mx-auto sm:iv-my-[42px]',
              'xl:iv-py-5 iv-py-2',
              'iv-max-h-[260px] sm:iv-max-h-full',
              'md:iv-order-2',
              'md:iv-mr-35',
            )}
          >
            <ImageComponent
              elementype="native"
              src={mediaUrl}
              alt="webinar"
              className={ivclass('iv-w-full iv-h-full iv-object-contain')}
              loading="lazy"
            />
          </div>
        </div>
        <div
          className={ivclass(
            'md:iv-py-20 md:iv-pr-30',
            'xl:iv-p-40 iv-p-35 sm:iv-p-25',
            '!iv-pt-5',
            'md:iv-flex md:iv-flex-col md:iv-justify-between md:iv-h-full',
          )}
        >
          <div
            className={ivclass(
              'xl:iv-pt-15 iv-pt-5 sm:iv-pt-1 iv-pb-20',
              'iv-text-grey-85',
              'xl:iv-text-body-3xl iv-text-body-2xl sm:iv-text-body-xl',
              'iv-m-body-3xl sm:iv-m-body-xl',
              'iv-font-medium',
            )}
          >
            {content}
          </div>
          <div className="">
            <button
              type="button"
              className={ivclass(
                'iv-border-2 iv-border-blue iv-rounded-full',
                'iv-inline-flex iv-justify-center iv-items-center',
                'iv-cursor-pointer',
                'iv-px-20 iv-py-[14px] sm:iv-px-10 sm:iv-py-5',
                'iv-text-blue',
                'iv-text-body-2xl sm:iv-text-body-xl',
                'iv-font-semibold iv-text-center',
                'iv-transition-all',
                'hover:iv-bg-blue hover:iv-text-grey-0',
              )}
              onClick={onClick}
            >
              {ctaText}
            </button>
          </div>
        </div>
      </div>

      <div
        className={ivclass(
          'iv-hidden md:iv-flex iv-justify-center iv-items-center',
          'iv-mx-auto sm:iv-my-[42px]',
          'xl:iv-py-5 iv-py-2',
          'md:iv-order-2',
          'md:iv-flex-1',
          'iv-w-full iv-h-full',
        )}
      >
        <ImageComponent
          elementype="native"
          src={tabletMedia}
          alt="webinar"
          height={60}
          width={60}
          className={ivclass('iv-w-full iv-h-full iv-object-contain')}
          loading="lazy"
        />
      </div>
    </IVCard>
  );
};

export default LargeCards;
