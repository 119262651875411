import ivclass from '@invideoio/web-shared/Modules/IVClass';
import LargeCards from '@components/lets-connect-v2/LargeCards';
import { BrowserProps, LetsConnect } from '../../types';
import TitleH2BlockGradient from '@components/common/Title/TitleH2BlockGradient';
import SmallCardV2 from './SmallCardV2';
import { AssetsAppName, getWebAssetsUrl } from '@utils/web-assets';

interface LetsConnectProps extends BrowserProps {
  isMobile: boolean;
  data: LetsConnect;
}

const LetsConnectV2 = (props: LetsConnectProps) => {
  const { isMobile, browser, data } = props;

  const initLiveChat = () => {
    const liveChatElement = document.getElementById('fresh-chat-custom');
    if (liveChatElement) {
      liveChatElement.click();
    }
  };

  return (
    <section
      className={ivclass(
        'xl:iv-px-20 lg:iv-px-40 md:iv-px-25 sm:iv-px-20',
        'xl:iv-pt-200 iv-pt-150 sm:iv-pt-100',
      )}
    >
      <div
        className={ivclass(
          'iv-grid',
          'sm:iv-px-5',
          'xl:iv-mb-75 iv-mb-50 sm:iv-mb-30',
        )}
      >
        <TitleH2BlockGradient
          heading={data.title}
          spanClass="iv-block sm:iv-inline"
        />
      </div>
      <div
        className={ivclass(
          'iv-grid',
          'iv-grid-cols-12',
          'iv-gap-y-30 iv-gap-x-40 md:iv-gap-25 sm:iv-gap-20',
        )}
      >
        <div
          className={ivclass(
            'iv-col-span-6 md:iv-col-span-12 sm:iv-col-span-12',
          )}
        >
          <LargeCards
            browser={browser}
            title={data.Card1.title}
            mediaUrl={data.Card1.media.url}
            content={data.Card1.description}
            ctaAction={data.Card1?.CtaLink || initLiveChat}
            ctaText={data.Card1.CtaText}
            isWebinar
          />
        </div>
        <div
          className={ivclass(
            'iv-col-span-6 md:iv-col-span-12 sm:iv-col-span-12',
          )}
        >
          <LargeCards
            browser={browser}
            title={data.Card2.title}
            mediaUrl={data.Card2.media.url}
            content={data.Card2.description}
            ctaAction={data.Card2.CtaLink}
            ctaText={data.Card2.CtaText}
            tabletMedia={getWebAssetsUrl(
              '/homepage/connect-tiles-tablet.png',
              AssetsAppName.LANDING_PAGES,
            )}
          />
        </div>
        {data.Card3 && data.Card4 && (
          <div
            className={ivclass(
              'iv-col-span-12 md:iv-col-span-12 sm:iv-col-span-12',
            )}
          >
            <div
              className={ivclass(
                'iv-grid',
                'iv-grid-cols-12',
                'iv-gap-40 md:iv-gap-25 sm:iv-gap-20',
              )}
            >
              <div
                className={ivclass(
                  'iv-col-span-6 sm:iv-col-span-12',
                  isMobile && 'iv-order-1',
                )}
              >
                <SmallCardV2
                  title={data.Card3.title}
                  details={data.Card3.description}
                  linkText={data.Card3.CtaText}
                  link={data.Card3.CtaLink}
                  mediaUrl={data.Card3.media.url}
                  medium="udemy"
                />
              </div>
              <div
                className={ivclass(
                  'iv-col-span-6 sm:iv-col-span-12',
                  isMobile && 'iv-order-2',
                )}
              >
                <SmallCardV2
                  title={data.Card4.title}
                  details={data.Card4.description}
                  linkText={data.Card4.CtaText}
                  link={data.Card4.CtaLink}
                  mediaUrl={data.Card4.media.url}
                  medium="facebook"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default LetsConnectV2;
