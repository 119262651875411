import React from 'react';

import IVCard from '@invideoio/web-shared/components/Card';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import {
  ClickCtaUxSource,
  ClickCtaType,
  AnalyticsProductType,
} from 'src/types/analytics.types';

interface SmallCardV2Props {
  title: string;
  details: string;
  linkText: string;
  link: string;
  mediaUrl?: string;
  medium?: string;
}

const SmallCardV2 = (props: SmallCardV2Props) => {
  const { title, details, linkText, link, mediaUrl, medium } = props;

  const handleAction = () => {
    setTimeout(() => {
      window.open(link, '_blank');
    }, 20);
  };

  const onClick = () => {
    const eventData: any = {
      'Button Position': 'Lets Connect',
    };
    if (medium) {
      eventData['Medium'] = medium;
    }
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickSocialHandle, eventData);
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.SocialHandleSmallCard,
        type: ClickCtaType.ClickSocialHandle,
        feature_utm: 'na',
        product: AnalyticsProductType.STUDIO,
      },
    });
    handleAction();
  };

  return (
    <IVCard
      className={ivclass(
        'iv-flex iv-relative',
        'md:iv-flex-col sm:iv-flex-col',
        'iv-items-center',
        'xl:iv-px-40 xl:iv-py-35 iv-p-35 sm:iv-p-25',
        'iv-h-full',
        'xl:iv-h-[260px] lg:iv-h-[260px] md:iv-min-h-[240px]',
        'iv-rounded-30',
        'iv-bg-black-4',
      )}
    >
      <div
        className={ivclass(
          'iv-flex-1 iv-h-full',
          'iv-flex iv-flex-col iv-justify-between iv-items-start',
        )}
      >
        <div>
          <div
            className={ivclass(
              'iv-text-heading-3xs iv-m-heading-3xs',
              'iv-font-extrabold',
            )}
          >
            {title}
          </div>
          <div
            className={ivclass(
              'iv-flex',
              'xl:iv-mt-10 lg:iv-pb-[27px] iv-pb-10 iv-mt-15 sm:iv-pb-[21px]',
              'xl:iv-text-body-3xl iv-text-body-2xl sm:iv-text-body-xl',
              'iv-font-medium iv-text-grey-85',
              'iv-max-w-md',
            )}
          >
            {details}
          </div>
        </div>

        <div
          className={ivclass(
            'iv-w-full iv-h-full xl:iv-mt-15 iv-flex sm:iv-flex-col lg:iv-items-end iv-items-start',
          )}
        >
          <div
            className={ivclass(
              'iv-absolute sm:iv-block sm:iv-relative iv-right-[-10px] sm:iv-right-0 iv-bottom-0',
              'iv-flex',
            )}
          >
            <ImageComponent
              elementype="native"
              src={mediaUrl}
              alt="webinar"
              height={60}
              width={60}
              className={ivclass(
                'iv-w-[260px] xl:iv-h-[200px] lg:iv-h-[200px] md:iv-h-[150px] md:iv-w-[150px] sm:iv-w-[150px] iv-object-contain',
              )}
              loading="lazy"
            />
          </div>

          <button
            type="button"
            className={ivclass('iv-cursor-pointer md:iv-mt-50')}
            onClick={onClick}
          >
            <span
              className={ivclass(
                'iv-flex iv-justify-center iv-items-center',
                'iv-cursor-pointer',
                'iv-border-2 iv-border-blue hover:iv-bg-blue hover:iv-text-grey-0',
                'iv-rounded-full',
                'iv-px-20 iv-py-[14px] sm:iv-px-10 sm:iv-py-5 sm:iv-mt-20',
                'iv-text-body-2xl iv-m-body-2xl sm:iv-text-body-xl sm:iv-m-body-xl iv-text-blue iv-font-semibold iv-transition-all',
              )}
            >
              {linkText}
            </span>
          </button>
        </div>
      </div>
    </IVCard>
  );
};

export default SmallCardV2;
